import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate();

    return (
        <div className='w-full mt-[100px]  borderFooter relative'>
            <div className='hidden lg:flex justify-between gap-[20px] xl:gap-[160px] mx-auto py-[45px] lg:w-11/12 px-[20px] relative'>
                {/* <img src="https://dz1x1c630cl14.cloudfront.net/webassets/glow3.svg" alt='glow' className='absolute -top-[50px] sm:-top-[200px] lg:-top-[450px] left-1/2 -translate-x-1/2 z-0' /> */}
                <div>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/one_ai-Logo1.svg" alt='logo' className='w-[160px] ml-[28px]' width={160} height={32}/>
                </div>
                <div className='flex items-start w-8/12 justify-between relative z-[1]'>
                    <div className='flex flex-col gap-[15px]'>
                        <h4 className='text-[#FFFFFF] text-[14px] font-semibold'>Insights</h4>
                        <div className='flex flex-col gap-[10px]'>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300 relative z-[1]' onClick={() => { navigate('/workfast_benefits_slack') }}>Slack Alternative</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300 relative z-[1]' onClick={() => { navigate('/workfast_benefits_task_management') }}>Task management</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/workfast_benefits_to-do-lists') }}>Better than todoist</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/workfast_benefits_time_focus') }}>Time and focus</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/whatsapp-vs-workfast') }}>Better than WhatsApp</h5>
                        </div>
                    </div>
                    {/* <div className='flex flex-col gap-[15px]'>
                        <h4 className='text-[#FFFFFF] text-[14px] font-semibold'>Features</h4>
                    </div> */}
                    <div className='flex flex-col gap-[15px] relative z-[1]'>
                        <h4 className='text-[#FFFFFF] text-[14px] font-semibold'>Compare</h4>
                        <div className='flex flex-col gap-[10px]'>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/monday-vs-workfast') }}>vs Monday</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/asana-vs-workfast') }}>vs Asana</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/slack-vs-workfast') }}>vs Slack</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/trello-vs-workfast') }}>vs Trello</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/notion-vs-workfast') }}>vs Notion</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/basecamp-vs-workfast') }}>vs Basecamp</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/todoist-vs-workfast') }}>vs Todoist</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/smartsheets-vs-workfast') }}>vs Smartsheet</h5>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h4 className='text-[#FFFFFF] text-[14px] font-semibold'>Blog</h4>
                        <div className='flex flex-col gap-[10px] max-w-[282px]'>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/blog') }}>Beginner's Guide to Product Management </h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai') }}>Breaking Barriers in Communication and Collaboration</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/blog/top-7-ways-workfast.ai-can-revolutionise-your-project-management') }}>Top 7 ways Workfast.ai can Revolutionise your project management</h5>
                            <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/blog/looking-for-a-new-platform-for-project-management') }}>Looking for a new platform for project management ? Discover Workfast.ai</h5>
                        </div>
                    </div>

                </div>
            </div>
            <div className='flex gap-[40px] lg:hidden justify-between flex-wrap py-[30px] w-11/12 mx-auto px-[20px] relative'>
                {/* <img src="https://dz1x1c630cl14.cloudfront.net/webassets/glow3.svg" alt='glow' className='absolute -top-[50px] sm:-top-[200px] lg:-top-[450px] left-1/2 -translate-x-1/2 z-[0]' /> */}
                <div>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/one_ai-Logo1.svg" alt='logo' className='w-[160px] sm:ml-[28px]' />
                </div>
                <div className='flex flex-col gap-[15px] relative z-[1]'>
                    <h4 className='text-[#FFFFFF] text-[14px] font-semibold'>Insights</h4>
                    <div className='flex flex-wrap sm:flex-col gap-x-[40px] gap-y-[10px] sm:gap-[10px]'>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/workfast_benefits_slack') }}>Slack Alternative</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/workfast_benefits_task_management') }}>Task management</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/workfast_benefits_to-do-lists') }}>Better than todoist</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/workfast_benefits_time_focus') }}>Time and focus</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/whatsapp-vs-workfast') }}>Better than WhatsApp</h5>
                    </div>
                </div>
                <div className='flex flex-col gap-[15px] relative z-[1]'>
                    <h4 className='text-[#FFFFFF] text-[14px] font-semibold'>Compare</h4>
                    <div className='flex flex-wrap sm:flex-col gap-x-[40px] gap-y-[10px] sm:gap-[10px]'>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/monday-vs-workfast') }}>vs Monday</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/asana-vs-workfast') }}>vs Asana</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/slack-vs-workfast') }}>vs Slack</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/trello-vs-workfast') }}>vs Trello</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/notion-vs-workfast') }}>vs Notion</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/basecamp-vs-workfast') }}>vs Basecamp</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/todoist-vs-workfast') }}>vs Todoist</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/compare/smartsheets-vs-workfast') }}>vs Smartsheet</h5>
                    </div>
                </div>
                <div className='flex flex-col gap-[15px] relative z-[1]'>
                    <h4 className='text-[#FFFFFF] text-[14px] font-semibold'>Blog</h4>
                    <div className='flex flex-col gap-[10px] max-w-[282px]'>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/blog') }}>Beginner's Guide to Product Management </h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai') }}>Breaking Barriers in Communication and Collaboration</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/blog/top-7-ways-workfast.ai-can-revolutionise-your-project-management') }}>Top 7 ways Workfast.ai can Revolutionise your project management</h5>
                        <h5 className='text-[#CBCBCB] text-[14px] font-medium cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/blog/looking-for-a-new-platform-for-project-management') }}>Looking for a new platform for project management ? Discover Workfast.ai</h5>
                    </div>
                </div>
            </div>
            <div className='border-[#575757] border-t-[1px] mx-[8px] py-[30px] relative z-[1]'>
            {/* <img src="https://dz1x1c630cl14.cloudfront.net/webassets/glow3.svg" alt='glow' className='absolute -top-[50px] sm:-top-[300px] lg:-top-[600px]  left-1/2 -translate-x-1/2 lg:-translate-x-0 lg:-left-[300px] z-0 w-[100px]' /> */}
                <div className='w-10/12 mx-auto flex justify-between flex-wrap gap-[20px] items-center relative z-10'>
                    <div className='flex items-center gap-[10px] flex-wrap'>
                        <a href="https://apps.apple.com/in/app/workfast-team-productivity/id6504078096" target="_blank" rel="noopener noreferrer">
                            <div className='flex gap-[20px] items-center p-[10px] pr-[22px] border-[1px] border-[#000] bg-[#0D1530] rounded-[14px]'>
                                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/appstore.svg" alt='appstore' className='w-[25px] sm:w-[30px]' />
                                <div className='flex flex-col justify-between'>
                                    <h3 className='text-[#FFFFFF] text-[12px] font-normal'>Download on the</h3>
                                    <h2 className='text-[#FFFFFF] text-[16px] font-semibold'>App Store</h2>
                                </div>
                            </div>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.workfast.ai.app" target="_blank" rel="noopener noreferrer">
                            <div className='flex gap-[20px] items-center p-[10px] pr-[22px] border-[1px] border-[#000] bg-[#0D1530] rounded-[14px]'>
                                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/playstore.svg" alt='appstore' className='w-[25px] sm:w-[30px]' />
                                <div className='flex flex-col justify-between'>
                                    <h3 className='text-[#FFFFFF] text-[12px] font-normal'>GET IT ON</h3>
                                    <h2 className='text-[#FFFFFF] text-[16px] font-semibold'>Google Play</h2>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='flex gap-[20px] flex-wrap items-center text-[12px] text-[#CBCBCB] font-normal'>
                        <h4>Copyright © 2024, Pepul Tech Privated Limited. All rights reserved.</h4>
                        <h4 className='cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/privacy') }}>Privacy</h4>
                        <h4 className='cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/terms') }}>Terms</h4>
                        <h4 className='cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/about') }}>About</h4>
                        <h4 className='cursor-pointer hover:text-[#FFF] transition-colors duration-300' onClick={() => { navigate('/contact') }}>Contact</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}