import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/landing";
import NewLanding from "./pages/newLanding";
import ContactUs from "./pages/contact";
// import Refund from './pages/refund';
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
// import Home from './pages/home 2';
// import Navbar from './pages/navbar';
// import Main from './pages/main';
import Main1 from './pages/main1';
import Blog from './pages/blog';
import Calender from './pages/calender';
import LandingPage from './pages/landingpage';
import LandingPageInd from './pages/landingpageInd';
import Form from './pages/form';
import Success from './pages/success';
import Signup from './pages/signup';
import PaymentSignup from "./pages/paymentSignup";
import MainSuccess from './pages/mainsuccess';
import Home from './pages/home';
import Error from './pages/error';
import Help from './pages/help';
import Investors from './pages/investor';
import InvestorFrom from './pages/investorForm';
import About from './pages/about';
import Articles1 from './pages/articles/articles1';
import Articles2 from './pages/articles/articles2';
import Articles3 from './pages/articles/articles3';
import Articles4 from './pages/articles/articles4';
import Payment from './pages/payment';
import Pricing from './pages/price';
import Partnership from './pages/partnership';
import ScrollToTop from './ScrollTop';
import AffiliateForm from './pages/partnership/affiliate-form';
import ResellerForm from './pages/partnership/resller-form';
import ReleaseNotes from './pages/releasenotes';
import CamparisonTodoist from './pages/landingpages/todoist';
import CompaignSlack from './pages/landingpages/slack';
import TimeAndFocus from './pages/landingpages/time&focus';
import TaskManagement from './pages/landingpages/taskmanagement';
import Asana from './pages/comparison/asana';
import Monday from './pages/comparison/monday';
import Todoist from './pages/comparison/todoist';
import Slack from './pages/comparison/slack';
import Trello from './pages/comparison/trello';
import Smartsheets from './pages/comparison/smartsheets';
import Notion from './pages/comparison/notion';
import Basecamp from './pages/comparison/basecamp';
import Whatsapp from './pages/comparison/whatsapp';
import StartupTn from './pages/startuptn';
import Deals from "./pages/deals";
import Offer from "./pages/offer";

export default function App() {
  const [email, setEmail] = React.useState("");
  const [pricingPackage, setPricingPackage] = React.useState("");
  const [priceIndex, setPriceIndex] = React.useState(null);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* <Route path="/page" element={<Landing />} /> */}
        {/* <Route path='/main' element={<Main1/>}/> */}
        {/* <Route path='/home' element={<Home setPriceIndex={setPriceIndex}/>}/> */}
        <Route path="/" element={<Main1 />} />
        <Route path="/*" element={<Error />} />
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai"
          element={<Articles1 />}
        />
        <Route
          path="/blog/top-7-ways-workfast.ai-can-revolutionise-your-project-management"
          element={<Articles2 />}
        />
        <Route
          path="/blog/beginner-guide-to-product-management"
          element={<Articles3 />}
        />
        <Route
          path="/blog/looking-for-a-new-platform-for-project-management"
          element={<Articles4 />}
        />
        {/* <Route path='/landing' element={<LandingPage/>}/> */}
        {/* <Route path='/landingin' element={<LandingPageInd email={email} setEmail={setEmail}/>}/> */}
        {/* <Route path='/development' element={<NewLanding/>}/> */}
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        {/* <Route path='/calendly' element={<Calender/>}/> */}
        <Route path='/form' element={<Form/>}/>
        <Route path='/landingin/form' element={<Form/>}/>
        <Route path='/startuptn' element={<StartupTn/>}/>
        <Route path='/startuptn/success' element={<Success/>}/>
        <Route path='/form-success' element={<Success/>}/>
        <Route path='/landingin/success' element={<Success/>}/>
        <Route path='/success' element={<MainSuccess/>}/>
        <Route path='/signup' element={<Signup setPricingPackage={setPricingPackage} pricingPackage={pricingPackage}/>}/>
        <Route
          path="/payment-signup"
          element={
            <PaymentSignup
              setPricingPackage={setPricingPackage}
              pricingPackage={pricingPackage}
            />
          }
        />
        <Route path='/help' element={<Help/>}/>
        <Route path='/investor' element={<Investors/>}/>
        <Route path='/investor/form' element={<InvestorFrom/>}/>
        <Route path='/payment' element={<Payment priceIndex={priceIndex}/>}/>
        <Route path='/pricing' element={<Pricing setPricingPackage={setPricingPackage}/>}/>
        <Route path='/releasenotes' element={<ReleaseNotes/>}/>
        <Route path='/partnership-program' element={<Partnership/>}/>
        <Route path='/partnership-program/affiliate-form' element={<AffiliateForm/>}/>
        <Route path='/partnership-program/reseller-form' element={<ResellerForm/>}/>
        <Route path='/workfast_benefits_to-do-lists' element={<CamparisonTodoist/>}/>
        <Route path='/workfast_benefits_slack' element={<CompaignSlack/>}/>
        <Route path='/workfast_benefits_time_focus' element={<TimeAndFocus/>}/>
        <Route path='/workfast_benefits_task_management' element={<TaskManagement/>}/>
        <Route path='/compare/asana-vs-workfast' element={<Asana/>}/>
        <Route path='/compare/monday-vs-workfast' element={<Monday/>}/>
        <Route path='/compare/todoist-vs-workfast' element={<Todoist/>}/>
        <Route path='/compare/slack-vs-workfast' element={<Slack/>}/>
        <Route path='/compare/trello-vs-workfast' element={<Trello/>}/>
        <Route path='/compare/smartsheets-vs-workfast' element={<Smartsheets/>}/>
        <Route path='/compare/notion-vs-workfast' element={<Notion/>}/>
        <Route path='/compare/basecamp-vs-workfast' element={<Basecamp/>}/>
        <Route path='/compare/whatsapp-vs-workfast' element={<Whatsapp/>}/>
        <Route path="/launch-offer" element={<Offer />} />
      </Routes>
    </Router>
  );
}
